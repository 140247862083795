var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CContainer',{staticClass:"login-common text-center",attrs:{"fluid":""}},[_c('CRow',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/img/logo-nissan-white.png"),"alt":"logo-nissan"}})]),_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo-login.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.forgotPassword),expression:"forgotPassword"}]},[_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"8","md":"8","lg":"6","xxl":"6"}},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.messages.passwordCreation.title))])])],1),_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"6","md":"4","lg":"3","xxl":"2"}},[_c('p',{staticClass:"instructions"},[_vm._v(" "+_vm._s(_vm.messages.passwordCreation.instructions)+" ")])])],1),_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"6","md":"4","lg":"3","xxl":"2"}},[_c('CForm',{attrs:{"novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.handlePasswordCreation.apply(null, arguments)}}},[_c('CInput',{attrs:{"placeholder":_vm.messages.passwordCreation.passwordPlaceholder,"type":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(
              _vm.passwordValidated === true &&
              !this.isValidPassword &&
              _vm.password.length > 1
            )?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.messages.validation.passwordError)+" ")]):_vm._e(),(
              _vm.errorBlocks.password1Required === true && _vm.password.length === 0
            )?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.messages.validation.required)+" ")]):_vm._e(),_c('CInput',{attrs:{"placeholder":_vm.messages.passwordCreation.passwordRepeatPlaceholder,"type":"password","required":""},on:{"keyup":_vm.passwordsMatch},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),(_vm.passwordsNotMatch)?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.messages.validation.passwordsNotMatch)+" ")]):_vm._e(),(
              _vm.errorBlocks.password2Required === true &&
              _vm.passwordRepeat.length === 0
            )?_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.messages.validation.required)+" ")]):_vm._e(),_c('CButton',{attrs:{"type":"submit","color":"primary","block":true}},[_vm._v(_vm._s(_vm.messages.passwordCreation.button))])],1),(_vm.errorBlocks.passwordNoCreated === true)?_c('ErrorMessage',{staticClass:"error-message error-message--bottom",attrs:{"message":_vm.errorManager.message}}):_vm._e()],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.forgotPassword),expression:"!forgotPassword"}]},[_c('CRow',{attrs:{"align-horizontal":"center"}},[_c('CCol',{attrs:{"sm":"6","md":"4","lg":"3","xxl":"2"}},[_c('p',{staticClass:"instructions"},[_vm._v(" "+_vm._s(_vm.messages.passwordCreation.passwordCreated)+" ")]),_c('CButton',{attrs:{"color":"primary","block":true,"to":"/"}},[_vm._v(_vm._s(_vm.messages.passwordCreation.buttonLogin))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }