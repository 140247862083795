<!--
  This component contains the form for create a new password for the user.
  This view must be receive 2 parameters: token and lang
-->
<template>
  <CContainer fluid class="login-common text-center">
    <CRow class="header">
      <img src="~@/assets/img/logo-nissan-white.png" alt="logo-nissan" />
    </CRow>
    <CRow align-horizontal="center">
      <img src="~@/assets/img/logo-login.png" class="logo" />
    </CRow>
    <div v-show="forgotPassword">
      <CRow align-horizontal="center">
        <CCol sm="8" md="8" lg="6" xxl="6"
          ><h3 class="title">{{ messages.passwordCreation.title }}</h3>
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="2">
          <p class="instructions">
            {{ messages.passwordCreation.instructions }}
          </p>
        </CCol>
      </CRow>
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="2">
          <CForm @submit.prevent="handlePasswordCreation" novalidate="true">
            <CInput
              :placeholder="messages.passwordCreation.passwordPlaceholder"
              type="password"
              v-model="password"
              required
            />
            <p
              v-if="
                passwordValidated === true &&
                !this.isValidPassword &&
                password.length > 1
              "
              class="error-message"
            >
              {{ messages.validation.passwordError }}
            </p>
            <p
              v-if="
                errorBlocks.password1Required === true && password.length === 0
              "
              class="error-message"
            >
              {{ messages.validation.required }}
            </p>
            <CInput
              :placeholder="messages.passwordCreation.passwordRepeatPlaceholder"
              type="password"
              v-model="passwordRepeat"
              required
              v-on:keyup="passwordsMatch"
            />
            <p v-if="passwordsNotMatch" class="error-message">
              {{ messages.validation.passwordsNotMatch }}
            </p>
            <p
              v-if="
                errorBlocks.password2Required === true &&
                passwordRepeat.length === 0
              "
              class="error-message"
            >
              {{ messages.validation.required }}
            </p>

            <CButton type="submit" color="primary" :block="true">{{
              messages.passwordCreation.button
            }}</CButton>
          </CForm>
          <ErrorMessage
            v-if="errorBlocks.passwordNoCreated === true"
            :message="errorManager.message"
            class="error-message error-message--bottom"
          />
        </CCol>
      </CRow>
    </div>
    <div v-show="!forgotPassword">
      <CRow align-horizontal="center">
        <CCol sm="6" md="4" lg="3" xxl="2">
          <p class="instructions">
            {{ messages.passwordCreation.passwordCreated }}
          </p>
          <CButton color="primary" :block="true" to="/">{{
            messages.passwordCreation.buttonLogin
          }}</CButton>
        </CCol>
      </CRow>
    </div>
  </CContainer>
</template>

<script>
import languages from "@/languages/languages";
import validation from "../utils/validation";
import LoginAndPassword from "@/services/LoginAndPassword";
const ErrorMessage = () =>
  import(/* webpackChunkName: "error-message" */ "@/components/atoms/ErrorMessage");
export default {
  name: "PasswordCreation",
  components: {
    ErrorMessage,
  },
  data() {
    return {
      token: null,
      password: "",
      passwordRepeat: "",
      passwordsNotMatch: false,
      forgotPassword: true,
      newUser: false,
      languages: languages,
      lang: this.$route.params.lang,
      passwordValidated: false,
      errorBlocks: {
        password1Required: false,
        password2Required: false,
        password1NoValid: false,
        passwordNoCreated: false,
      },
      errorManager: null,
    };
  },
  methods: {
    /**
     * Validate the form and check if the values of both entries are the same.
     */
    formValidation(obj) {
      if (!this.isValidPassword) {
        this.errorBlocks.password1NoValid = true;
      }
      if (!validation.rules().required(this.password)) {
        this.errorBlocks.password1Required = true;
      }

      if (!validation.rules().required(this.passwordRepeat)) {
        this.errorBlocks.password2Required = true;
      }

      this.passwordsMatch();

      if (
        this.isValidPassword &&
        validation.rules().required(this.password) &&
        validation.rules().required(this.passwordRepeat) &&
        this.passwordsNotMatch === false
      ) {
        this.submitForm(obj);
      }
    },
    /**
     * Submit form. Manage errors
     */
    submitForm(obj) {
      console.log(obj);
      LoginAndPassword()
        .post("resetPassword", obj)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.code === 200) {
              this.forgotPassword = false;
            } else {
              this.errorBlocks.passwordNoCreated = true;
              this.errorManager = {
                message: `${this.messages.errors[response.data.code]}`,
              };
            }
          } else {
            this.errorBlocks.passwordNoCreated = true;
            this.errorManager = {
              message: `${this.messages.errors[response.status]}`,
            };
          }
        })
        .catch((error) => console.log("error", error));
    },
    /**
     * Passing values to form validation
     */
    handlePasswordCreation() {
      this.passwordValidated = true;
      const newPasswordRecovery = {
        newPassword: this.password,
        token: this.token,
      };
      this.formValidation(newPasswordRecovery);
    },
    /**
     * Check if the passwords match
     */
    passwordsMatch() {
      this.password != this.passwordRepeat
        ? (this.passwordsNotMatch = true)
        : (this.passwordsNotMatch = false);
    },
  },
  computed: {
    messages() {
      return this.languages.texts(this.lang);
    },
    isValidPassword() {
      return validation.rules().password(this.password);
    },
  },
  beforeCreate() {
    /**
     * Check that the URL parameters are filled in, if not redirect to page 404
     */
    if (
      typeof this.$route.params.token === "undefined" &&
      typeof this.$route.params.newUser === "undefined"
    ) {
      this.$router.push({ name: "page404" });
    }
  },
  created() {
    this.token = this.$route.params.token;
    console.log(`The data token is ${this.token}`);
  },
};
</script>
